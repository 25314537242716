import DefaultLayout from "../../../layouts/DefaultLayout";
import {Table} from "../../tables/BasicTable";
import React, {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import BoxLayout from "../../../layouts/BoxLayout";
import DefaultButton from "../../buttons/DefaultButton";
import {ApiContext} from "../../contexts/ApiContext";

import {useNavigate, useParams} from "react-router-dom";
import H2Header from "../../headers/h2";
import TextInput from "../../input/TextInput";
import EditCompanyVisitModal from "../../modals/EditCompanyVisitModal";
import EditChildModal from "../../modals/EditChildModal";
import {SmallTable} from "../../tables/SmallTable";
import PresentSelectionModal from "../../modals/PresentSelectionModal";
import {MdEdit} from "react-icons/md";


function AdminCompanyVisitDetailPage() {
    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [companyVisitDetails, setCompanyVisitDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataAvailable, setDataAvailable] = useState(false)
    const [openEditCompanyVisitModal, setOpenEditCompanyVisitModal] = useState(false);
    const [openEditChildModal, setOpenEditChildModal] = useState(false);
    const [openPresentSelectionModal, setOpenPresentSelectionModal] = useState(false);
    const [rowDataPresent, setRowDataPresent] = useState([])
    const [rowData, setRowData] = useState([])
    const [Email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const navigate = useNavigate();

    // Get ID from URL
    const params = useParams();

    const getColumnsChildern = () => [
        {
            Header: "ID",
            accessor: "ID",
        },
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Achternaam",
            accessor: "LastName",
        },
        {
            Header: "Leeftijd",
            accessor: "Age",
        },
        {
            Header: "Email Ourder/verzorger",
            accessor: "Email",
        },
        {
            Header: "Naam Ouder/verzorger",
            accessor: "ParentName",
        },
        {
            id: "edit-button",
            Cell:  <MdEdit />
        }

    ];
    const getColumnsContactPerson = () => [
        {
            Header: "ID",
            accessor: "ID",
        },
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Achternaam",
            accessor: "LastName",
        },
        {
            Header: "Email",
            accessor: "Email",
        },
        {
            id: "edit-button",
            Cell:  <MdEdit />
        }
    ];
    const getColumnsPresents = () => [
        {
            Header: "Voornaam",
            accessor: "FirstName",
        },
        {
            Header: "Cadeau",
            accessor: "PresentName",
        },
        {
            id: "edit-button",
            Cell:  <MdEdit />
        }

    ];
    const handleDownload = () => {
        fetch(Url + "admin/company-visit-export/" + params.id, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download =  companyVisitDetails.Company + ".xlsx" || "downloaded-file";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };
    function getCompanyVisitDetails(){
        if (token) {
            fetch(Url + 'admin/company-visit-details/' + params.id, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setDataAvailable(true)
                        return res.json()
                    }else{
                        setDataAvailable(false)
                        return [{
                            "message": "Geen informatie voor dit bedrijfsbezoek beschikbaar."
                        }]
                    }
                })
                .then((data) => {
                    setCompanyVisitDetails(data)
                })
                .finally(() => {
                    setLoading(false)

                })

        }
    }
    function addContactPerson(){
        //Api excepts an array, create one.
        setMessage('Contactpersoon wordt gezocht in de database....')
        fetch(Url + 'admin/company-visits-add-contact-person', {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                ID: companyVisitDetails.ID,
                Email: Email,
            }),
        })
            .then((res) => {
                if (res.status === 200) {
                    setMessage('Toevoegen van contactpersoon gelukt.')
                    getCompanyVisitDetails()
                }else{
                    setMessage('Contactpersoon niet gevonden in database, controleer het mailadres. Nieuwe gebruiker? Voeg dan een gebruiker eerst toe.')
                }
            })
    }
    useEffect(() => {
            setLoading(true)
            getCompanyVisitDetails()
        },
        [token, Url]);
    useEffect(() => {
            getCompanyVisitDetails()
        },
        [openEditCompanyVisitModal, openEditChildModal, openPresentSelectionModal]);
    function handleRowData (rowDataInput){
        setRowData(rowDataInput)
        if (rowData){
            setOpenEditChildModal(true)
        }
    }
    function handleRowDataPresents (rowDataInput){
        setRowDataPresent(rowDataInput)
        if (setRowDataPresent){
            setOpenPresentSelectionModal(true)
        }
    }
    let dataTable
    if (companyVisitDetails.Children){
        dataTable =  (<Table tableData={companyVisitDetails.Children} getColumns={getColumnsChildern()} onRowClick={handleRowData}></Table>)
    }else {
        dataTable = <div>Er zijn nog geen kinderen ingeschreven voor dit bezoek</div>
    }
    let contactPersonTable
    if (companyVisitDetails.Users){
        contactPersonTable =  (<Table tableData={companyVisitDetails.Users} getColumns={getColumnsContactPerson()}></Table>)
    }else{
        contactPersonTable = <div>Er zijn nog geen contactpersonen voor dit bezoek toegevoegd.</div>
    }
    let childPresentTable
    if (companyVisitDetails.PresentInfo){
        childPresentTable = (<Table tableData={companyVisitDetails.PresentInfo} setRowdata={setRowData        }
               onRowClick={handleRowDataPresents}
               getColumns={getColumnsPresents()}></Table>)
    }else {
        childPresentTable = (<div>Er zijn nog geen kinderen ingeschreven voor dit bezoek</div>)
    }


    let presentServiceInfo
    if (companyVisitDetails.PresentServiceActive){
        presentServiceInfo = (
            <div>
                <div className="text-xl">Cadeauservice actief: JA</div>
                <div className="text-xl">Cadeauservice categorie: {companyVisitDetails.PresentCategory}</div>
            </div>)
    }else{
        presentServiceInfo = (
            <div>
                <div className="text-xl">Cadeauservice actief: Nee</div>
            </div>)
    }
    let lockedInfo
    if (companyVisitDetails.Locked){
        lockedInfo = (
            <div className=" my-4 text-xl font-extrabold text-red-600 ">GROTE BOEK VOOR DIT BEZOEK STAAT DICHT! INFORMATIE KAN NIET GEWIJZIGD WORDEN DOOR KLANTEN!</div>
        )
    }else{
        lockedInfo = (<div className="my-4 text-xl font-extrabold text-red-600">LOCK NIET ACTIEF INFORMATIE KAN DOOR KLANTEN GEWIJZIGD WORDEN!</div>)
    }
    let dataViewer
    if (dataAvailable) {
        let SignUpTarget
        SignUpTarget = "/company/welcome?signup_code="+companyVisitDetails.SignUpCode
        dataViewer = (
            <BoxLayout>
                <div className="mb-4 mt-4">
                    <DefaultButton content="Terug"
                                   onClick={() => navigate("/admin/company-visits/", {replace: false})}></DefaultButton>
                </div>
                <H2Header headerText={companyVisitDetails.Company}/>
                <H2Header headerText="Algemeen"/>
                {lockedInfo}
                <div className="text-xl">Datum: {companyVisitDetails.Date}</div>
                <div className="text-xl">Starttijd: {companyVisitDetails.StartTime}</div>
                <div className="text-xl">Eindtijd: {companyVisitDetails.EndTime}</div>
                <div className="text-xl">Aantal ingeschreven kinderen: {companyVisitDetails.NumberOfChildren}</div>
                <div className="text-xl">Aantal ingeschreven volwassen: {companyVisitDetails.NumberOfAdults}</div>
                <div className="text-xl">Inschrijfdeadline (Zichtbaar voor
                    klanten): {companyVisitDetails.SoftSignUpDeadline}</div>
                <div className="text-xl">Inschrijfdeadline (Afgedwongen in
                    systeem): {companyVisitDetails.HardSignUpDeadline}</div>
                <div className="text-xl">Aanmeldcode: {companyVisitDetails.SignUpCode}</div>
                <div className="text-xl">Aanmeldlink: <a target="_blank" href={SignUpTarget}
                                                         rel="noreferrer">{window.location.origin + "/company/welcome?signup_code=" + companyVisitDetails.SignUpCode}</a>
                </div>

                {presentServiceInfo}
                <div className="mb-4 mt-4">
                    <DefaultButton content="Bewerken" onClick={() => setOpenEditCompanyVisitModal(true)}></DefaultButton>
                </div>
                <div className="mb-4 mt-4">
                    <DefaultButton content="Download" onClick={handleDownload}></DefaultButton>
                </div>

                <H2Header headerText="Locatie"/>
                <div className="text-xl">Adres: {companyVisitDetails.Address}</div>
                <div className="text-xl">Postcode: {companyVisitDetails.Zipcode}</div>
                <div className="text-xl">Plaats: {companyVisitDetails.City}</div>
                <H2Header headerText="Contactpersonen"/>
                {contactPersonTable}
                <p className="text-base leading-relaxed text-red-500">
                    {message}
                </p>
                <TextInput
                    labelName="Email nieuw contactpersoon"
                    inputName="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={Email}
                    placeholder="Email"
                    errorMessage="Geen geldig mailadres"
                    pattern={undefined}
                    type="email"
                />
                <DefaultButton content="Toevoegen" onClick={addContactPerson}></DefaultButton>
                <H2Header headerText="Ingeschreven kinderen"/>
                {dataTable}
                {companyVisitDetails.PresentServiceActive &&  <H2Header headerText="Cadeauselectie"/>}

                {companyVisitDetails.PresentServiceActive &&  childPresentTable}

                {companyVisitDetails.PresentServiceActive && <PresentSelectionModal openModal={openPresentSelectionModal}
                                                                             setOpenModal={() => setOpenPresentSelectionModal()}
                                                                             presents={companyVisitDetails.Presents}
                                                                             rowData={rowDataPresent} welcomePage={false} admin={true}/>}
            </BoxLayout>
        )
    } else {
        dataViewer = (
            <BoxLayout>
                <div>Geen data voor dit bezoek in de database</div>
            </BoxLayout>
        )
    }
    return (
        <DefaultLayout>
            <EditCompanyVisitModal openModal={openEditCompanyVisitModal} setOpenModal={setOpenEditCompanyVisitModal} visitInfo={companyVisitDetails}/>
            <EditChildModal openModal={openEditChildModal} setOpenModal={setOpenEditChildModal} welcomePage={false} rowData={rowData} admin={true}/>
            {loading ? (
                <BoxLayout>
                    <div>Loading...</div></BoxLayout>

            ) : (
                <div className="flex flex-col overflow-auto py-4 sm:py-0">
                    {dataViewer}
                </div>
            )}
        </DefaultLayout>
    )
}
export default AdminCompanyVisitDetailPage