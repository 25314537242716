
"use client";

import {Modal} from "flowbite-react";
import TextInput from "../input/TextInput";
import React, {useContext, useState} from "react";
import DefaultButton from "../buttons/DefaultButton";
import NumberInput from "../input/NumberInput";
import SelectInput from "../input/SelectInput";
import TextArea from "../input/TextArea";
import {ValidationContext} from "../contexts/ValidationContext";
import {ApiContext} from "../contexts/ApiContext";
import {useAuth} from "../../providers/authProvider";

function NewChildModal(
    props: {
        openModal: boolean,
        setOpenModal: boolean,
        setChildren?: any,
        children?: any,
        welcomePage: boolean,
        signUpCode?: string,
    }
) {
    const {nameRegex, nameErrorMessage} = useContext(ValidationContext)
    const {Url } = useContext(ApiContext)
    const { token } = useAuth();
    const [FirstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('')
    const [Age, setAge] = useState('')
    const [Siblings, setSiblings] = useState('')
    const [Parents, setParents] = useState('')
    const [SintSit, setSintSit] = useState('')
    const [School, setSchool] = useState('')
    const [Hobbies, setHobbies] = useState('')
    const [message, setMessage] = useState('')

    function addChild() {
        if (FirstName === '' || LastName === '' || Age === '' || Parents === '' ||  Hobbies === ''){
            setMessage('Nog niet alle verplichte velden zijn ingevuld.')
            return
        }
        if (SintSit === '' || SintSit === '0'){
            setMessage('Selecteer een optie bij "Wil dit kind op schoot bij Sinterklaas?"')
            return
        }
        if (props.welcomePage) {
            addChildWelcome()
        } else {
            addChildVisitorPage()
        }
        //clear state data
        setFirstName('')
        setLastName('')
        setAge('')
        setSiblings('')
        setParents('')
        setSintSit('')
        setHobbies('')
        setSchool('')
        setMessage('')
    }
    function addChildWelcome(){

        let ChildID
        if (props.children && props.children.length > 0){
            ChildID = Math.max(...props.children.map(child => child.ID), 0)
        }else{
            ChildID = 0
        }
        ChildID = ChildID + 1

        let child = {
            ID: ChildID,
            FirstName: FirstName,
            LastName: LastName,
            Age: Number(Age),
            Siblings: Siblings,
            Parents: Parents,
            SintSit: SintSit,
            School: School,
            Hobbies: Hobbies
        }
        let childrenInModal = props.children
        childrenInModal.push(child)
        props.setChildren(childrenInModal)
        props.setOpenModal(false)
    }
    function addChildVisitorPage(){
        setMessage("Bezig met opslaan...")
        fetch(Url + 'user/children', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                FirstName : FirstName,
                LastName: LastName,
                Age: Number(Age),
                Parents: Parents,
                Hobbies: Hobbies,
                Siblings: Siblings,
                SintSit: SintSit,
                School: School,
                SignUpCode: props.signUpCode,

            }),
        }).then((res) => {
            if (res.status === 200) {
                //Saving successfully.
                setMessage("")
                props.setOpenModal(false)
            } else {
                //Saving failed
                setMessage("Het opslaan is mislukt, neem contact op.")
            }
        })
    }
    return (
        <Modal  show={props.openModal} position="center" onClose={() => props.setOpenModal(false)} >
            <Modal.Header><p className="m-4">Nieuw kind toevoegen</p></Modal.Header>
            <Modal.Body>
                <div className="space-y-6 m-4">
                    <p className="text-base leading-relaxed text-gray-500">
                        Geeft de gegevens van het kind op en klik op toevoegen. Vragen met een * zijn verplichte velden.
                    </p>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <TextInput labelName="Voornaam*"
                               inputName="firstName"
                               onChange={(e) => setFirstName(e.target.value)}
                               value={FirstName}
                               placeholder="Voornaam"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <TextInput labelName="Achternaam*"
                               inputName="lastName"
                               onChange={(e) => setLastName(e.target.value)}
                               value={LastName}
                               placeholder="Achternaam"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <NumberInput labelName="Leeftijd*"
                                 inputName="age"
                                 onChange={(e) => setAge(e.target.value)}
                                 value={Age}
                                 placeholder="Leeftijd"
                                 errorMessage="Vul hier de leeftijd van het kind in. Gebruik alleen getallen. De maximale leeftijd is 17 jaar."
                                 type="number"
                                 max={17}
                                 min={0}
                    />
                    <TextInput labelName="Naam van broer(s) en/of zus(sen)"
                               inputName="siblings"
                               onChange={(e) => setSiblings(e.target.value)}
                               value={Siblings}
                               placeholder="Naam(en)"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <TextInput labelName="Naam van ouders en/of verzorgers*"
                               inputName="partens"
                               onChange={(e) => setParents(e.target.value)}
                               value={Parents}
                               placeholder="Naam(en)"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <SelectInput labelName="Wil dit kind op schoot bij Sinterklaas?*"
                                 inputName="sintSit"
                                 onChange={(e) => setSintSit(e.target.value)}
                                 value={SintSit}
                                 errorMessage="Selecteer een van de opties uit de lijst"
                                 options={
                                     [
                                         {
                                             ID: "Ja leuk",
                                             Name: "Ja leuk"
                                         },
                                         {
                                             ID: "Ja is vorig jaar ook geweest",
                                             Name: "Ja is vorig jaar ook geweest"
                                         },
                                         {
                                             ID: "Waarschijnlijk niet",
                                             Name: "Waarschijnlijk niet"
                                         },
                                         {
                                             ID: "Geen idee",
                                             Name: "Geen idee"
                                         }
                                     ]
                                 }
                    />
                    <TextInput labelName="Naam van de school "
                               inputName="school"
                               onChange={(e) => setSchool(e.target.value)}
                               value={School}
                               placeholder="Naam van de School"
                               errorMessage={nameErrorMessage}
                               pattern={nameRegex}
                               type="text"
                    />
                    <TextArea labelName="Hobby's en/of sporten*"
                              inputName="hobbies"
                              onChange={(e) => setHobbies(e.target.value)}
                              value={Hobbies}
                              placeholder="Hobby's en/of sporten"
                              rows={1}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="mb-10 grid grid-cols-1 gap-0 md:grid-cols-2">
                <div className=" m-4 flex justify-center">
                        <DefaultButton onClick={addChild} content="Toevoegen" />
                    </div>
                    <div className="m-4 flex justify-center">
                        <DefaultButton onClick={() => props.setOpenModal(false)} content="Annuleren" />
                    </div>
                </div>

            </Modal.Footer>
        </Modal>
    );
}
export default NewChildModal
