import { useNavigate } from "react-router-dom";
import React, {useContext, useState} from "react";
import {ApiContext} from "../contexts/ApiContext";
import FormLayout from "../../layouts/FormLayout";
import H1Header from "../headers/h1";
import TextInput from "../input/TextInput";
import PasswordInput from "../input/PasswordInput";
import DefaultButton from "../buttons/DefaultButton";


function PasswordResetPage() {
    const {Url } = useContext(ApiContext)
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [token, setToken] = useState('')
    const [password, setPassword] = useState('')
    const [passwordResetStep, setPasswordResetStep] = useState(1)
    const [message, setMessage] =useState('')

    function requestPasswordReset(event) {
        event.preventDefault()
        fetch(Url + 'password/reset-request', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                email: email
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message === 'Password reset requested') {
                    setPasswordResetStep(2)
                }
            })
    }
    function passwordReset(event) {
        event.preventDefault()
        setMessage('Bezig met het resetten van je wachtwoord...')
        fetch(Url + 'password/reset', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                token: token.replace(/\s/g,''), //Remove all whitespace
                password: password
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message === 'Password reset done') {
                    setPasswordResetStep(3)
                    setPassword('')
                    setEmail('')
                    setToken('')
                } if (data.message === 'Validation failed for field: Password'){
                    setMessage('Het opgegeven wachtwoord is niet lang genoeg.')
                }
                else{
                    setMessage('Het resetten van je wachtwoord is mislukt. Controleer de token.')
                }
            })
    }
    function goToLoginPage(){
        navigate("/", { replace: true })

    }
    if (passwordResetStep === 1){
        return (
            <FormLayout>
                <form onSubmit={requestPasswordReset}>
                    <H1Header headerText="Wachtwoord vergeten?" ></H1Header>
                    <div className="m-4 ">
                        Vul je mail adres in en klik op reset wachtwoord.
                    </div>
                    <TextInput
                        labelName="Emailadres"
                        inputName="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        pattern={undefined}
                        type="email"
                        errorMessage={"Vul een geldig mailadres in"}>
                    </TextInput>

                    <div className="flex justify-end">
                        <DefaultButton content="Reset wachtwoord" onClick={requestPasswordReset}></DefaultButton>
                    </div>
                </form>
            </FormLayout>
        );
    }
    if (passwordResetStep === 2){
        return (
            <FormLayout>
                <form onSubmit={requestPasswordReset}>
                    <H1Header headerText="Wachtwoord vergeten?"></H1Header>
                    <div className="mb-4 mt-4 max-w-[30rem]">
                        Als je mailadres voorkomt in onze database is er een mail verstuurd naar je mailadres. Kopieer
                        de token uit deze mail en plak deze in het token veld. Vul een nieuw wachtwoord in en klik op de
                        knop “Reset wachtwoord”. Je nieuwe wachtwoord dient minimaal 10 karakters lang te zijn.
                    </div>
                    <p className="text-base leading-relaxed text-red-500">
                        {message}
                    </p>
                    <TextInput
                        labelName="Emailadres"
                        inputName="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        pattern={undefined}
                        type="email"
                        errorMessage={"Vul een geldig mailadres in"}>
                    </TextInput>
                    <TextInput
                        labelName="Token"
                        inputName="token"
                        placeholder="Token"
                        onChange={(e) => setToken(e.target.value)}
                        value={token}
                        pattern={undefined}
                        type="text"
                        errorMessage={"Vul een het token uit de mail in."}>
                    </TextInput>
                    <PasswordInput labelName="Wachtwoord" value={password} inputName="password"
                                   onChange={(e) => setPassword(e.target.value)}
                                   errorMessage="Ongeldig wachtwoord, je wachtwoord moet minimaal 10 tekens lang zijn"
                                   pattern=".{10,}"/>
                    <div className=" mt-4 flex justify-end">
                        <DefaultButton content="Reset Wachtwoord" onClick={passwordReset}></DefaultButton>
                    </div>
                </form>
            </FormLayout>
        );
    }
    if (passwordResetStep === 3) {
        return (
            <FormLayout>
            <H1Header headerText="Wachtwoord vergeten?" ></H1Header>
                <div className="m-4 max-w-[30rem]">
                    Gelukt! Je wachtwoord is opnieuw ingesteld. Je kan nu nu inloggen met je nieuwe wachtwoord.
                </div>
                <div className="m-4 flex justify-end">
                    <DefaultButton content="Inloggen" onClick={goToLoginPage}></DefaultButton>
                </div>
            </FormLayout>
        );
    }

}

export default PasswordResetPage;
