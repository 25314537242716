import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../providers/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Login from "../components/pages/Login";
import Logout from "../components/pages/Logout";
import ProfilePage from "../components/pages/user/Profile";
import PageNotFound from "../components/pages/404";
import AdminUsersPage from "../components/pages/admin/Users";
import CompanyWelcomePage from "../components/pages/company/Welcome";
import AdminPresentsPage from "../components/pages/admin/Presents";
import AdminCompaniesPage from "../components/pages/admin/Companies";
import PasswordReset from "../components/pages/PasswordReset";
import AdminCompanyVisitsPage from "../components/pages/admin/CompanyVisits";
import AdminCompanyVisitDetailPage from "../components/pages/admin/CompanyVisitDetails";
import ContactPersonVisitDetailPage from "../components/pages/company/ContactPersonVisitDetails";
import ContactPersonVisitsOverviewPage from "../components/pages/company/ContactPersonCompanyVisitsOverview";
import VisitorOverviewPage from "../components/pages/user/VisitorOverview";

const Routes = () => {
    const { token } = useAuth();

    // Define routes accessible only to authenticated users
    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
            children: [
                {
                    path: "/",
                    element: <ProfilePage />,
                },
                {
                    path: "*",
                    element: <PageNotFound />,
                },
                {
                    path: "/user/profile",
                    element: <ProfilePage />,
                },
                {
                    path: "/user/company-visits-overview",
                    element: <VisitorOverviewPage />,
                },
                {
                    path: "/company/visits-overview/",
                    element: <ContactPersonVisitsOverviewPage/>,
                },
                {
                    path: "/company/visit-details/:id",
                    element: <ContactPersonVisitDetailPage/>,
                },
                {
                    path: "/admin/users",
                    element: <AdminUsersPage />,
                },
                {
                    path: "/admin/companies",
                    element: <AdminCompaniesPage />,
                },
                {
                    path: "/admin/company-visits",
                    element: <AdminCompanyVisitsPage />,
                },
                {
                    path: "/admin/company-visit-details/:id",
                    element: <AdminCompanyVisitDetailPage />,
                },

                {
                    path: "/admin/presents",
                    element: <AdminPresentsPage />,
                },
                {
                    path: "/logout",
                    element: <Logout/>,
                },
                {
                    path: "/company/welcome",
                    element: <CompanyWelcomePage/>,
                },
            ],
        },
    ];

    // Define routes accessible only to non-authenticated users
    const routesForNotAuthenticatedOnly = [
        {
            path: "/login",
            element: <Login/>,
        },
        {
            path: "/company/welcome",
            element: <CompanyWelcomePage/>,
        },
        {
            path: "/password-reset",
            element: <PasswordReset/>,
        },
    ];

    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
        ...(!token ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router} />;
};

export default Routes;