
import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const AuthContext = createContext(undefined);

const AuthProvider = ({ children }) => {
    // State to hold the authentication token
    const [token, setToken_] = useState(sessionStorage.getItem("token"));

    // Function to set the authentication token
    const setToken = (newToken) => {
        setToken_(newToken);
    };

    useEffect(() => {
        if (token) {
            let decodedToken = jwtDecode(token);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                // Token invalid
                sessionStorage.removeItem("token");
            } else {
                // Token valid
                sessionStorage.setItem("token", token);
            }
        } else {
            // Token is not set.
            sessionStorage.removeItem("token");
        }
    }, [token]);

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            token,
            setToken,
        }),
        [token]
    );

    // Provide the authentication context to the children components
    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;
