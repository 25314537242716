import DefaultLayout from "../../../layouts/DefaultLayout";
import {useContext, useEffect, useState} from "react";
import {useAuth} from "../../../providers/authProvider";
import DlElement from "../../lists/DlElement"
import BoxHeader from "../../../layouts/BoxHeader";
import DlWrapper from "../../lists/DlWrapper";
import BoxLayout from "../../../layouts/BoxLayout";
import {ApiContext} from "../../contexts/ApiContext";
function ProfilePage() {
    const {Url } = useContext(ApiContext)
    const [firstName, setFirstname] = useState('')
    const [lastName, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const { token } = useAuth();
    useEffect(() => {
        if (token) {
            try {
                fetch(Url + 'user/profile', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': "Bearer " + token,
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setEmail(data.Email)
                        setPhone(data.Phone)
                        setFirstname(data.FirstName)
                        setLastname(data.LastName)
                    })
            }catch (error) {
                console.log("Unable to load data")
                console.log('Error: ', error)
            }
        }
    }, [token, Url]);

    return (
            <BoxLayout>
                <BoxHeader headerText="Mijn Account" subText="Bekijk hier jouw profielgegevens" />
                    <DlWrapper>
                            <DlElement color="gray-50" dt="Voornaam" dd={firstName} />
                            <DlElement color="white" dt="Achternaam" dd={lastName} />
                            <DlElement color="gray-50" dt="Telefoonnummer" dd={phone} />
                            <DlElement color="white" dt="E-mail" dd={email} />
                    </DlWrapper>
            </BoxLayout>
    );
}

export default ProfilePage;
