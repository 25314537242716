import React, { createContext } from 'react'
export const ApiContext = createContext()
const ApiContextProvider = (props) => {
    const Url = "https://api-preview.ridderendeprins.nl/"
    return (
        <ApiContext.Provider
            value={{
                Url
            }}>
            {props.children}
        </ApiContext.Provider>
    )
}
export default ApiContextProvider